import React from 'react';
import Layout from '../../components/layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ContentCopy from '../../components/content-copy';
// import ContentImageXL from '../../components/content-image-xl';
// import ContentImageL from '../../components/content-image-l';
// import ContentImageM from '../../components/content-image-m';


const VolantisDS = ({ data }) => (
    <Layout>
        <section className="columns is-centered case-studies is-gapless is-multiline">
            <div className="column is-12">
                <Img
                    className="featured-image"
                    fluid={data.imageOne.childImageSharp.fluid}
                    alt="Featured Image"
                />
            </div>
            <div className="column is-12 padding-bottom-0 padding-top-0">
                <div className="columns is-centered">
                    <div className="column is-5 is-offset-1">
                        <div className="page-title-wrap margin-bot-3">
                            <span className="title-decoratiion"></span>
                            <h1 className="general-page-title">Student App - Course</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-5 padding-top-0">
                <div className="table-wrapper">
                    <table className="table is-fullwidth">
                        <tbody>
                            <tr>
                                <td><h2>Client</h2></td>
                                <td><p>Gredu - EduTech</p></td>
                            </tr>
                            <tr>
                                <td><h2>Overview</h2></td>
                                <td>
                                    <p>Build new income stream with monthly subscription model that separated from main product purchase. Working with CCO, CTO, PM, Visual Designers, and Content to crafting the right product in series of workshops.</p>
                                    <br />
                                    <p>It's like merging two big products, this course system covers course module, subscription, bookmark and social share, study Progress, evaluation, and scoring or leveling with rewards.</p>
                                </td>
                            </tr>
                            <tr>
                                <td><h2>Outcome</h2></td>
                                <td>
                                    <p>After some iterations, the MVP version reduced a lot of features in order to get developed and delivered faster, more details in related section below.</p>
                                </td>
                            </tr>
                            <tr>
                                <td><h2>Timeline</h2></td>
                                <td><p>It took roughly 2 months from ideation workshops until final approved design.</p></td>
                            </tr>
                            <tr>
                                <td><h2>My Role</h2></td>
                                <td><p>Product Design</p></td>
                            </tr>
                            <tr>
                                <td><h2>Tools</h2></td>
                                <td><p>Sketch App, Abstract, Zeplin, InVision.</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="columns is-centered padding-top-0 is-multiline">
                <div className="column is-12">
                    <ContentCopy>
                        <h3>The full case study is coming soon</h3>
                        <p>In the meantime, you can check out my other projects or let's set up a session so I can present this project for you.</p>
                        <br />
                        <p><strong>willy.syafiq@gmail.com</strong></p>
                    </ContentCopy>
                </div>
            </div>
            <div className="column is-12">
                <hr />
            </div>

            {/* More Cases Section  */}
            <div className="column is-10">
                <div className="columns is-centered is-multiline">
                    <div className="column is-12">
                        <h3 className="has-text-centered margin-top-0">More Case Studies</h3>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/gredu-design-system-v0.1">
                                        <Img fluid={data.imageFour.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/gredu-design-system-v0.1"><h2 className="read-more-title">Gredu Design System V.01</h2></Link>
                                        <Link to="/case-studies/gredu-design-system-v0.1"><p className="read-more-excerp">A design system starter that will cover more than 5 products in the school ecosystem (web, iOS, & Android).</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/hris-markplus">
                                        <Img fluid={data.imageThree.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/hris-markplus"><h2 className="read-more-title">HRIS MarkPlus, Inc.</h2></Link>
                                        <Link to="/case-studies/hris-markplus"><p>Human resource information system that covers attendance, leave, benefits, employees information, etc.</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/volantis-design-system">
                                        <Img fluid={data.imageFive.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/volantis-design-system"><h2 className="read-more-title">Volantis Design System</h2></Link>
                                        <Link to="/case-studies/volantis-design-system"><p className="read-more-excerp">The design language that speaks for B2B startup with a modern approach. Covering foundation, UI Kit (for designer & engineer), design assets central.</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout >
);

export default VolantisDS;


export const query = graphql`
  query studentAppCourseQuery {
    imageOne:file(relativePath: { eq: "student-app-course-hero.png" }) {
      childImageSharp {
        fluid(maxWidth:2000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo:file(relativePath: { eq: "hris-markplus-mobile-samples.png" }) {
        childImageSharp {
          fluid(maxWidth:2000, quality:80) {
            ...GatsbyImageSharpFluid
          }
        }
    }
    imageThree:file(relativePath: { eq: "hris-markplus.png" }) {
        childImageSharp {
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageFour:file(relativePath: { eq: "gredu-ds-page.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }
    imageFive:file(relativePath: { eq: "volantis-ds-page.png" }) {
        childImageSharp{
            fluid{
                ...GatsbyImageSharpFluid
            }
        }
    }

  }
`
